import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let admissionEnquiry_url = `${SCHOOL_BASE}/admissionEnquiry`;
// status
export const getStatusList = (payload) =>
  schoolTokenAxios.get(`${admissionEnquiry_url}/getStatusList`, { payload });

export const addStatus = (body) =>
  schoolTokenAxios.post(`${admissionEnquiry_url}/addStatus`, body);

export const editStatus = (body) =>
  schoolTokenAxios.put(`${admissionEnquiry_url}/editStatus`, body);

export const deleteStatus = (statusDocId) =>
  schoolTokenAxios.delete(
    `${admissionEnquiry_url}/deleteStatus/${statusDocId}`
  );
// source
export const getSourceList = (payload) =>
  schoolTokenAxios.get(`${admissionEnquiry_url}/getSourceList`, { payload });

export const addSource = (body) =>
  schoolTokenAxios.post(`${admissionEnquiry_url}/addSource`, body);

export const editSource = (body) =>
  schoolTokenAxios.put(`${admissionEnquiry_url}/editSource`, body);

export const deleteSource = (sourceId) =>
  schoolTokenAxios.delete(`${admissionEnquiry_url}/deleteSource/${sourceId}`);

export const addAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addAdmissionEnquiry`,
    payload
  );
};

export const getAdmissionEnquiry = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getAdmissionEnquiry`, {
    params: params,
  });
};
export const getAdmissionEnquiryTable = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getAdmissionEnquiryTable`,
    {
      params: params,
    }
  );
};
export const getAdmissionEnquiryCount = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getAdmissionEnquiryCount`,
    {
      params: params,
    }
  );
};

export const changeEnquiryStatusOfAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/changeEnquiryStatusOfAdmissionEnquiry`,
    payload
  );
};
export const updateAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/updateAdmissionEnquiry`,
    payload
  );
};
export const editAdmissionProbability = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/editAdmissionProbability`,
    payload
  );
};
export const removeAssigneeInAdmissionEnquiryForm = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/removeAssigneeInAdmissionEnquiryForm`,
    payload
  );
};
export const changeStatusOfAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/changeStatusOfAdmissionEnquiry`,
    payload
  );
};

export const deleteAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.delete(
    `${admissionEnquiry_url}/deleteAdmissionEnquiry/${payload}`
  );
};

export const registerFreshStudentByAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/registerFreshStudentByAdmissionEnquiry`,
    payload
  );
};

//Form Fee Structure
export const getFormFeeStructure = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getFormFeeStructure`, {
    params: params,
  });
};

export const addFormFeeStructure = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addFormFeeStructure`,
    payload
  );
};

export const updateFormFeeStructure = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/updateFormFeeStructure`,
    payload
  );
};

// Previous School
export const getPreviousSchoolNameList = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getPreviousSchoolNameList`,
    {
      params: params,
    }
  );
};

export const addPreviousSchool = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addPreviousSchool`,
    payload
  );
};

export const editPreviousSchool = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/editPreviousSchool`,
    payload
  );
};

export const deleteClassFromPreviousSchool = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/deleteClassFromPreviousSchool`,
    payload
  );
};

export const deletePreviousSchool = (payload) => {
  return schoolTokenAxios.delete(
    `${admissionEnquiry_url}/deletePreviousSchool/${payload}`
  );
};

export const addClassInPreviousSchool = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addClassInPreviousSchool`,
    payload
  );
};

export const getClassListFromPreviousSchool = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getClassListFromPreviousSchool`,
    {
      params: params,
    }
  );
};

export const viewAdmissionEnquirybasicInfo = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/viewAdmissionEnquirybasicInfo`,
    {
      params: params,
    }
  );
};
export const getFollowUpdetail = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getFollowUpdetail`, {
    params: params,
  });
};
export const getDistinctStatus = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getDistinctStatus`, {
    params: params,
  });
};
export const getDistinctSources = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getDistinctSources`, {
    params: params,
  });
};

//communication thread
export const createAdmissionEnquiryThread = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/createAdmissionEnquiryThread`,
    payload
  );
};

export const editAdmissionEnquiryThread = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/editAdmissionEnquiryThread`,
    payload
  );
};

export const deleteAdmissionEnquiryThread = (payload) => {
  return schoolTokenAxios.delete(
    `${admissionEnquiry_url}/deleteAdmissionEnquiryThread/${payload}`
  );
};

export const getEnquiryCreatedByList = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getEnquiryCreatedByList`,
    {
      params: params,
    }
  );
};
export const collectFormPayment = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/collectFormPayment`,
    payload
  );
};
export const getCounselorDropdownList = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getCounselorDropdownList`,
    {
      params: params,
    }
  );
};

export const assignSingleAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/assignSingleAdmissionEnquiry`,
    payload
  );
};
export const assignMultipleAdmissionEnquiryToStaff = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/assignMultipleAdmissionEnquiryToStaff`,
    payload
  );
};

/***********************Counselor Api**************************/

export const getCounselor = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getCounselor`, {
    params: params,
  });
};

export const getDistinctAreaOfCounselor = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getDistinctAreaOfCounselor`,
    {
      params: params,
    }
  );
};

export const linkExistingStaffAsCounselor = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/linkExistingStaffAsCounselor`,
    payload
  );
};

export const addFreshCounselor = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addFreshCounselor`,
    payload
  );
};

export const updateCounselorArea = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/updateCounselorArea`,
    payload
  );
};

export const getAssignedFormDetailOfCounselor = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getAssignedFormDetailOfCounselor`,
    {
      params: params,
    }
  );
};
export const getBulkAdmissionEnquiryJsonFormat = (params) => {
  return schoolTokenAxios.get(
    `${admissionEnquiry_url}/getBulkAdmissionEnquiryJsonFormat`,
    {
      params: params,
    }
  );
};

/***********************Visuals Api**************************/

export const getFormCount = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getFormCount`, {
    params: params,
  });
};

export const getFormCountByGender = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getFormCountByGender`, {
    params: params,
  });
};

export const getFormCountWithClass = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getFormCountWithClass`, {
    params: params,
  });
};

export const bulkUploadAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/bulkUploadAdmissionEnquiry`,
    payload
  );
};
