import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import DialogHeader from "../DialogHeader";

const PdfSummaryPreviewDialog = ({
  open = false,
  excel = false,
  allSelected = false,
  handleClose = () => {},
  data,
  handleExportPdf,
  setSelectedOptionsForPdf,
  selectedOptionsForPdf,
  showOrientation = false,
  setOrientation = () => {},
  orientation = "landscape",
}) => {
  const handleCheckboxChangeForPdf = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptionsForPdf((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptionsForPdf((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      setSelectedOptionsForPdf(data);
    } else {
      setSelectedOptionsForPdf([]);
    }
  };

  return (
    <>
      {/* Dialog for selecting options for exporting pdf*/}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title={`${excel ? "Excel" : "PDF"} Export Options`}
          handleClose={handleClose}
        />
        <DialogContent>
          {allSelected && (
            <Box
              sx={{
                mb: 2,
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptionsForPdf?.length === data?.length}
                      onChange={(e) => handleSelectAllChange(e.target.checked)}
                    />
                  }
                  label="Select All"
                />
              </Stack>

              {showOrientation && (
                <>
                  <Stack direction="row">
                    <FormControlLabel
                      control={
                        <Radio
                          checked={orientation === "landscape"}
                          onChange={(e) =>
                            setOrientation(
                              e.target.checked ? "landscape" : "portrait"
                            )
                          }
                        />
                      }
                      label="Landscape"
                    />
                  </Stack>
                  <Stack direction="row">
                    <FormControlLabel
                      control={
                        <Radio
                          checked={orientation === "portrait"}
                          onChange={(e) =>
                            setOrientation(
                              e.target.checked ? "portrait" : "landscape"
                            )
                          }
                        />
                      }
                      label="Portrait"
                    />
                  </Stack>
                </>
              )}
            </Box>
          )}
          <Box>
            {data?.map((option) => (
              <Stack direction="row" key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptionsForPdf.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChangeForPdf(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              handleExportPdf();
              handleClose();
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PdfSummaryPreviewDialog;
