import {
  selectSelectedSchool,
  userNameSelector,
} from "app/modules/schools/selectors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PdfSummaryPreviewDialog from "../common/Dialogs/PdfSummaryPreviewDialog";
import jsPDF from "jspdf";
import reportHeader from "../common/pdfReport/reportHeader";
import { calculateTotalsAndAppendSummaryRowPdf } from "app/utils/helper";
import autoTable from "jspdf-autotable";
import Footer from "../common/pdfReport/ReportFooter";

const CommonPdfReport = ({
  header = [],
  exportArray,
  filterData = {},
  exportTitle = "Fee Collection.pdf",
  showFiter = false,
  open = false,
  onClose = () => {},
  totalColumns = [],
  showOrientation = false,
}) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [orientation, setOrientation] = useState("landscape");
  const userName = useSelector(userNameSelector);

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: orientation, // Use selected orientation (portrait or landscape)
    });

    const rows = [];
    exportArray.forEach((item) => {
      const row = selectedOptions?.map((option) => item[option]);
      rows.push(row);
    });

    const tableColumn = selectedOptions?.map((col) => ({
      title: col,
      dataKey: col,
    }));

    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 8,
        cellWidth: "auto",
      },
      bodyStyles: {
        fontSize: 8,
        cellWidth: "auto",
        textColor: [0, 0, 0],
      },
      columnStyles: {},
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";
    let filterTexts = [];

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
      orientation: orientation,
    });

    if (filterData) {
      Object.keys(filterData)
        .filter((key) => filterData[key])
        .forEach((key) => {
          const formattedKey = key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
          const value = filterData[key] || "--";
          filterTexts.push(`${formattedKey}: ${value}`);
        });
    }

    let yaxis = 35;
    if (filterTexts.length > 0) {
      doc.text(`Applied Filters:`, 15, yaxis);

      yaxis += 6;
      const maxWidth = orientation === "landscape" ? 270 : 180; // Adjust max width based on orientation
      const lineHeight = 6;
      let xaxis = 15;

      filterTexts.forEach((filterText) => {
        let words = filterText.split(" ");
        let line = "";

        words.forEach((word) => {
          let testLine = line + word + " ";
          let testWidth = doc.getTextWidth(testLine);
          if (testWidth > maxWidth) {
            doc.text(line.trim(), xaxis, yaxis);
            line = word + " ";
            yaxis += lineHeight;
          } else {
            line = testLine;
          }
        });

        if (line) {
          doc.text(line.trim(), xaxis, yaxis);
          yaxis += lineHeight;
        }
      });

      yaxis += 10;
    }

    rows.push(
      calculateTotalsAndAppendSummaryRowPdf(
        rows,
        setSelectedOptions,
        totalColumns
      )
    );

    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: yaxis,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
          orientation: orientation,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    // Show preview before saving
    doc.output("dataurlnewwindow", {
      filename: exportTitle,
    });

    // doc.save(exportTitle);
  };

  return (
    <>
      <PdfSummaryPreviewDialog
        excel={false}
        allSelected={true}
        open={open}
        handleClose={onClose}
        data={header}
        setSelectedOptionsForPdf={setSelectedOptions}
        selectedOptionsForPdf={selectedOptions}
        handleExportPdf={handleExportPdf}
        showOrientation={showOrientation}
        setOrientation={setOrientation}
        orientation={orientation}
      />
    </>
  );
};

export default CommonPdfReport;
