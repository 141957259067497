import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import appRoutes from "app/config/routes";
import {
  BillsUsagePage,
  SchoolProfilePage,
  SchoolsPage,
} from "app/modules/schools/pages";
import { AuthPage } from "../../modules/auth/pages";
import PrivateRoute from "../PrivateRoute";
import { StaffManagementPage, StaffProfilePage } from "app/modules/hr/pages";
import CreatePayroll from "app/modules/hr/components/CreatePayroll";
import DashboardIndex, {
  CoachingDashboardPage,
  CollegeDashboardPage,
  GroupOfInstitutesDashboardPage,
  SchoolDashboardPage,
} from "app/modules/dashboard/pages";
import Layout from "app/layout";
import SchoolManagementIndex, {
  SessionSettingsPage,
  DepartmentsPage,
  ClassesPage,
  AccessControlPage,
  InvitationCodePage,
} from "app/modules/school-management/pages";
import {
  VisitorControlPage,
  ComplainsPage,
  StaffAttendancePage,
} from "app/modules/reception/pages";
import {
  StudentManagementPage,
  CreateStudentPage,
  StudentProfilePage,
  StudentIdCardPage,
  ExistStudentPage,
  MigrationPage,
  EditMigrationPage,
  StudentReportPage,
} from "app/modules/student/pages";
import {
  AdmitCardPage,
  PrintAdmitCardPage,
  ReportCardPage,
  TestExamsPage,
} from "app/modules/exam-control/pages";
import ValidateSession from "app/modules/auth/pages/ValidateSession";
import {
  AttendanceAndLeavePage,
  HomeWorkPage,
  TimeTablePage,
  SyllabusPage,
  SubjectDetailsPage,
  AcademicCalendarPage,
} from "app/modules/academics/pages";
import FeeCollectioPage from "app/modules/fee-management/fee-collection";

// Import modules
import PromotionAndMigration from "app/modules/student/pages/PromotionAndMigration";
import PromoteModal from "app/modules/student/pages/PromotionAndMigration/PromoteModal";
import {
  CollectionPage,
  ExpensesPage,
  FirmInvoicePage,
  LedgerReportPage,
} from "app/modules/LedgerAccount";
import { FeeStructurePage, RouteDetailPage } from "app/modules/transport/pages";
import FeeStructure from "app/modules/fee-management/fee-structure";
import {
  NoticeBoardPage,
  TextSMSPage,
  WhatsAppPage,
} from "app/modules/communication/index";
import AddNewStaffPage from "app/modules/hr/pages/addNewStaff";
import { TransportDetailPage } from "app/modules/transport/pages";
import JoiningApproval from "app/modules/hr/pages/JoiningApproval";
import { AboutSchoolPage } from "app/modules/schools/pages";
import AdmissionApproval from "../../modules/student/pages/AdmissionApproval";
import { StaffLeavePage } from "app/modules/hr/pages";
import EditDetails from "app/modules/student/components/AdmissionApproval/EditDetails";
import InvoiceComponent from "app/components/Invoice/InvoiceComponent";
import { UserProfilePage } from "app/modules/userProfile/pages";
import UserHeader from "app/layout/Header/UserHeader";
import ApplySchool from "app/modules/schools/pages/Schools/ApplySchool";
import {
  DeviceDetailPage,
  PunchLogPage,
  UserDetailPage,
} from "app/modules/bio-metric/pages";
import ForgotPassword from "app/modules/auth/components/ForgotPassword";
import FirmsPage from "app/modules/LedgerAccount/pages/firms/Firms";
import FirmProfile from "app/modules/LedgerAccount/pages/firms/FirmProfile";
import BooksPage from "app/modules/LedgerAccount/pages/books/BooksPage";
import PaySlip from "app/modules/LedgerAccount/pages/PaySlip";
import PrintSlip from "app/modules/hr/components/PrintSlip";
import SubHeadsPage from "app/modules/LedgerAccount/pages/SubHeadsPage";
import ExpenseSubHeadsPage from "app/modules/LedgerAccount/pages/ExpenseSubHeads";
import InvoiceComponent2 from "../Invoice/InvoiceComponent2";
import StaffPayroll from "app/modules/hr/pages/StaffPayroll";
import {
  ArrearPage,
  CollectFeePage,
  FeeCollection2Page,
  FeeDiscountPage,
  FeesInvoicePage,
  FeesReportPage,
} from "app/modules/fee-management";
import SessionOrderInvoice from "../Invoice/SessionOrderInvoice";
import ArrearInvoice from "../Invoice/ArrearInvoice";
import AdmissionEnquiry from "app/modules/reception/pages/AdmissionEnquiry";
import Certificate from "app/modules/student/pages/Certificate";
import {
  AchievementsPage,
  AlumniAchieverPage,
  ChiefGuestPage,
  ClassPhotoPage,
  FounderPage,
  SchoolGalleryPage,
  SchoolTeamPage,
  SchoolTopperPage,
  SportAchieverPage,
  ThoughtOfTheDayPage,
  WebsitePopupPage,
  WebsiteSliderPage,
} from "app/modules/website-builder";
import YouTubeVideo from "app/modules/website-builder/YouTubeVideo";
import NewsCalendar from "app/modules/website-builder/NewsCalendar";
import AlumniIndex from "app/modules/student/pages/Alumni";
import BulkUpdate from "app/modules/student/pages/StudentManagment/BulkUpdate";
import {
  CounselorPage,
  CouselorProfilePage,
  EnquiryPage,
  FormFeeStructurePage,
  PreviousSchoolPage,
  VisualsPage,
} from "app/modules/admissionEnquiry";

import {
  PaymentConfigurationPage,
  PaymentOrderPage,
} from "app/modules/payemnt-gateway";

const routes = [
  {
    path: "/",
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      // login page
      {
        path: `/`,
        element: <Navigate to={`/${appRoutes.login}`} />,
      },
      // about school page
      {
        path: `/${appRoutes.schools}/about-school`,
        element: (
          <PrivateRoute>
            <AboutSchoolPage />
          </PrivateRoute>
        ),
      },
      // school profile page
      {
        path: `/${appRoutes.schools}/school-profile`,
        element: (
          <PrivateRoute>
            <SchoolProfilePage />
          </PrivateRoute>
        ),
      },
      // school bills and usage page
      {
        path: `/${appRoutes.billsUsage}`,
        element: (
          <PrivateRoute>
            <BillsUsagePage />
          </PrivateRoute>
        ),
      },
      // invoice page
      {
        path: `/${appRoutes.sessionOrderInvoice}/:invoiceId`,
        element: (
          <PrivateRoute>
            <SessionOrderInvoice />
          </PrivateRoute>
        ),
      },
      // dashboard
      {
        path: `/${appRoutes.dashboard}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.dashboard}`,
            element: (
              <PrivateRoute>
                <DashboardIndex />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.schoolDashboard}/:schoolId`,
            element: (
              <PrivateRoute>
                <SchoolDashboardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.collegeDashboard}/:collegeId`,
            element: (
              <PrivateRoute>
                <CollegeDashboardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.coachingDashboard}/:coachingId`,
            element: (
              <PrivateRoute>
                <CoachingDashboardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.groupOfInstitutesDashboard}`,
            element: (
              <PrivateRoute>
                <GroupOfInstitutesDashboardPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      //admission-enquiry
      {
        path: `/${appRoutes.admissionEnquiry}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.visuals}`,
            element: (
              <PrivateRoute>
                <VisualsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.enquiry}`,
            element: (
              <PrivateRoute>
                <EnquiryPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.counselor}`,
            element: (
              <PrivateRoute>
                <CounselorPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.formFeeStructure}`,
            element: (
              <PrivateRoute>
                <FormFeeStructurePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.previousSchool}`,
            element: (
              <PrivateRoute>
                <PreviousSchoolPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.counselor}/:counselorDocId`,
            element: (
              <PrivateRoute>
                <CouselorProfilePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.createNewStudent}/:admissionEnquiryDocId`,
            element: (
              <PrivateRoute>
                <CreateStudentPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // school management
      {
        path: `/${appRoutes.schoolManagement}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.schoolManagement}`,
            element: (
              <PrivateRoute>
                <SchoolManagementIndex />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.sessionSettings}`,
            element: (
              <PrivateRoute>
                <SessionSettingsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.departments}`,
            element: (
              <PrivateRoute>
                <DepartmentsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.classes}`,
            element: (
              <PrivateRoute>
                <ClassesPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.accessControl}`,
            element: (
              <PrivateRoute>
                <AccessControlPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.invitationCode}`,
            element: (
              <PrivateRoute>
                <InvitationCodePage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // reception
      {
        path: `/${appRoutes.reception}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.visitorControl}`,
            element: (
              <PrivateRoute>
                <VisitorControlPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.complaints}`,
            element: (
              <PrivateRoute>
                <ComplainsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.staffAttendance}`,
            element: (
              <PrivateRoute>
                <StaffAttendancePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.admission_enquiry}`,
            element: (
              <PrivateRoute>
                <AdmissionEnquiry />
              </PrivateRoute>
            ),
          },
        ],
      },
      // bio metric
      {
        path: `/${appRoutes.bioMetric}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.punchLog}`,
            element: (
              <PrivateRoute>
                <PunchLogPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.deviceDetail}`,
            element: (
              <PrivateRoute>
                <DeviceDetailPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.userDetail}`,
            element: (
              <PrivateRoute>
                <UserDetailPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // student management
      {
        path: `/${appRoutes.studentManagement}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.studentReport}`,
            element: (
              <PrivateRoute>
                <StudentReportPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.students}`,
            element: (
              <PrivateRoute>
                <StudentManagementPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.create_student}`,
            element: (
              <PrivateRoute>
                <CreateStudentPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.admissionApproval}`,
            element: (
              <PrivateRoute>
                <AdmissionApproval />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.bulkEdit}`,
            element: (
              <PrivateRoute>
                <BulkUpdate />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.certificate}`,
            element: (
              <PrivateRoute>
                <Certificate />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.student_profile}/:studentDocId`,
            element: (
              <PrivateRoute>
                <StudentProfilePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.promotionAndMigration}`,
            element: (
              <PrivateRoute>
                <PromotionAndMigration />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.edit_promotion_student_details}/:studentDocId`,
            element: (
              <PrivateRoute>
                <PromoteModal />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.migrate}/:studentDocId`,
            element: (
              <PrivateRoute>
                <MigrationPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.editMigrate}/:migrationDocId`,
            element: (
              <PrivateRoute>
                <EditMigrationPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.edit_admission_approval_details}`,
            element: (
              <PrivateRoute>
                <EditDetails />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.alumni}`,
            element: (
              <PrivateRoute>
                <AlumniIndex />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.idCard}`,
            element: (
              <PrivateRoute>
                <StudentIdCardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.existStudent}`,
            element: (
              <PrivateRoute>
                <ExistStudentPage />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: `/${appRoutes.transport}`,
        element: <Outlet />,
        children: [
          // {
          //   path: `/${appRoutes.transportDetail}`,
          //   element: (
          //     <PrivateRoute>
          //       <StudentManagementPage />
          //     </PrivateRoute>
          //   ),
          // },
          // {
          //   path: `/${appRoutes.routeDetail}`,
          //   element: (
          //     <PrivateRoute>
          //       <CreateStudentPage />
          //     </PrivateRoute>
          //   ),
          // },
          {
            path: `/${appRoutes.feeStructure}`,
            element: (
              <PrivateRoute>
                <FeeStructurePage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // communication
      {
        path: `/${appRoutes.communication}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.noticeBoard}`,
            element: (
              <PrivateRoute>
                <NoticeBoardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.textSms}`,
            element: (
              <PrivateRoute>
                <TextSMSPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.whatsApp}`,
            element: (
              <PrivateRoute>
                <WhatsAppPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // exam control
      {
        path: `/${appRoutes.examControl}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.reportCard}`,
            element: (
              <PrivateRoute>
                <ReportCardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.testsAndExams}`,
            element: (
              <PrivateRoute>
                <TestExamsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.admitCard}`,
            element: (
              <PrivateRoute>
                <AdmitCardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.printAdmitCard}/:classDocId/:section/:_id`,
            element: (
              <PrivateRoute>
                <PrintAdmitCardPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // academics
      {
        path: `/${appRoutes.academics}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.homework}`,
            element: (
              <PrivateRoute>
                <HomeWorkPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.timeTable}`,
            element: (
              <PrivateRoute>
                <TimeTablePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.attendanceAndLeave}`,
            element: (
              <PrivateRoute>
                <AttendanceAndLeavePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.syllabus}`,
            element: (
              <PrivateRoute>
                <SyllabusPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.academicCalendar}`,
            element: (
              <PrivateRoute>
                <AcademicCalendarPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.subject_details}/:subjectDocId`,
            element: (
              <PrivateRoute>
                <SubjectDetailsPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // fee management
      {
        path: `/${appRoutes.fees_management}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.fees}`,
            element: (
              <PrivateRoute>
                <FeeStructure />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.collectFee}`,
            element: (
              <PrivateRoute>
                <CollectFeePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.fees_collection2}`,
            element: (
              <PrivateRoute>
                <FeeCollection2Page />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.fees_collection}`,
            element: (
              <PrivateRoute>
                <FeeCollectioPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.arrear}`,
            element: (
              <PrivateRoute>
                <ArrearPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.fees_report}`,
            element: (
              <PrivateRoute>
                <FeesReportPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.fees_invoice}`,
            element: (
              <PrivateRoute>
                <FeesInvoicePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.fees_discount}`,
            element: (
              <PrivateRoute>
                <FeeDiscountPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.invoice}/:invoiceId`,
            element: (
              <PrivateRoute>
                <InvoiceComponent />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.NewInvoice}/:invoiceId`,
            element: (
              <PrivateRoute>
                <InvoiceComponent2 />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.arrearInvoice}/:invoiceId`,
            element: (
              <PrivateRoute>
                <ArrearInvoice />
              </PrivateRoute>
            ),
          },
        ],
      },
      // transportation
      {
        path: `/${appRoutes.transport}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.transportDetail}`,
            element: (
              <PrivateRoute>
                <TransportDetailPage />
              </PrivateRoute>
            ),
          },
          // {
          //   path: `/${appRoutes.expenses}`,
          //   element: (
          //     <PrivateRoute>
          //       <FeeCollectioPage />
          //     </PrivateRoute>
          //   ),
          // },
        ],
      },

      {
        path: `/${appRoutes.transport}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.routeDetail}`,
            element: (
              <PrivateRoute>
                <RouteDetailPage />
              </PrivateRoute>
            ),
          },
          // {
          //   path: `/${appRoutes.expenses}`,
          //   element: (
          //     <PrivateRoute>
          //       <FeeCollectioPage />
          //     </PrivateRoute>
          //   ),
          // },
        ],
      },
      // ledger and account
      {
        path: `/${appRoutes.ledger_and_account}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.collections}`,
            element: (
              <PrivateRoute>
                <CollectionPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.expenses}`,
            element: (
              <PrivateRoute>
                <ExpensesPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.ledger_repport}`,
            element: (
              <PrivateRoute>
                <LedgerReportPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.firmInvoice}`,
            element: (
              <PrivateRoute>
                <FirmInvoicePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.collectionSubHeads}`,
            element: (
              <PrivateRoute>
                <SubHeadsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.expenseSubHeads}`,
            element: (
              <PrivateRoute>
                <ExpenseSubHeadsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.firms}`,
            element: (
              <PrivateRoute>
                <FirmsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.firmProfile}/:id`,
            element: (
              <PrivateRoute>
                <FirmProfile />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.books}`,
            element: (
              <PrivateRoute>
                <BooksPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.paySlip}/:id`,
            element: (
              <PrivateRoute>
                <PaySlip />
              </PrivateRoute>
            ),
          },
          // {
          //   path: `/${appRoutes.collectionInvoice}/:id`,
          //   element: (
          //     <PrivateRoute>
          //       <PaySlipCollection />
          //     </PrivateRoute>
          //   ),
          // },
        ],
      },
      // human resource
      {
        path: `/${appRoutes.hr}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.staffManagement}`,
            element: (
              <PrivateRoute>
                <StaffManagementPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.joiningApproval}`,
            element: (
              <PrivateRoute>
                <JoiningApproval />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.staffProfile}/:staffDocId`,
            element: (
              <PrivateRoute>
                <StaffProfilePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.newRegistration}`,
            element: (
              <PrivateRoute>
                <AddNewStaffPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.staffLeave}`,
            element: (
              <PrivateRoute>
                <StaffLeavePage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.payroll}`,
            element: (
              <PrivateRoute>
                <StaffPayroll />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.createPayroll}/:id`,
            element: (
              <PrivateRoute>
                <CreatePayroll />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.printSlip}/:id`,
            element: (
              <PrivateRoute>
                <PrintSlip />
              </PrivateRoute>
            ),
          },
        ],
      },
      // website builder
      {
        path: `/${appRoutes.websiteBuilder}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.websiteSlider}`,
            element: (
              <PrivateRoute>
                <WebsiteSliderPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.schoolGallery}`,
            element: (
              <PrivateRoute>
                <SchoolGalleryPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.thoughtOfTheDay}`,
            element: (
              <PrivateRoute>
                <ThoughtOfTheDayPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.classPhoto}`,
            element: (
              <PrivateRoute>
                <ClassPhotoPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.schoolTeam}`,
            element: (
              <PrivateRoute>
                <SchoolTeamPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.websitePopup}`,
            element: (
              <PrivateRoute>
                <WebsitePopupPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.achievement}`,
            element: (
              <PrivateRoute>
                <AchievementsPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.schoolTopper}`,
            element: (
              <PrivateRoute>
                <SchoolTopperPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.sportAchiever}`,
            element: (
              <PrivateRoute>
                <SportAchieverPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.chiefGuest}`,
            element: (
              <PrivateRoute>
                <ChiefGuestPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.alumniAchiever}`,
            element: (
              <PrivateRoute>
                <AlumniAchieverPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.founder}`,
            element: (
              <PrivateRoute>
                <FounderPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.youtubeVideos}`,
            element: (
              <PrivateRoute>
                <YouTubeVideo />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.newsCalendar}`,
            element: (
              <PrivateRoute>
                <NewsCalendar />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.aboutSchoolWebsite}`,
            element: (
              <PrivateRoute>
                <AboutSchoolPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      //payment-controller
      {
        path: `/${appRoutes.paymentGateway}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.orders}`,
            element: (
              <PrivateRoute>
                <PaymentOrderPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.configuration}`,
            element: (
              <PrivateRoute>
                <PaymentConfigurationPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: `*`,
        element: <>Not Found</>,
      },
    ],
  },

  {
    path: `/${appRoutes.schools}`,
    element: (
      <PrivateRoute>
        <UserHeader />
        <SchoolsPage />
      </PrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.applySchool}`,
    element: (
      <PrivateRoute>
        <UserHeader />
        <ApplySchool />
      </PrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.userProfile}`,
    element: (
      <PrivateRoute>
        <UserHeader />
        <UserProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.login}`,
    element: <AuthPage />,
  },
  {
    path: `/${appRoutes.register}`,
    element: <AuthPage />,
  },
  {
    path: `/${appRoutes.forgotPassword}`,
    element: <ForgotPassword />,
  },
  // {
  //   path: `/${appRoutes.passwordChange}`,
  //   element: <PasswordChange />
  // },
  // {
  //   path: `/password-change`,
  //   element: <Navigate to={`/${appRoutes.passwordChange}`} />,
  // },
  {
    path: `/${appRoutes.validateSession}`,
    element: <ValidateSession />,
  },
];

const AppRoutes = () => {
  const appRoutes = useRoutes(routes);
  return appRoutes;
};

export default AppRoutes;
